<template>
  <v-layout wrap class="justify-center">
    <v-flex lg12 md12 sm12 pr-2>
      <v-container container--fluid>
        <v-layout>
          <v-breadcrumbs
            style="padding-left: 0px; padding-top: 5px; padding-bottom: 5px;"
            :items="breadcrumbs"
            divider="/"
          ></v-breadcrumbs>

        </v-layout>
        <v-layout>
          <page-title>Model logs</page-title>

          <div class="ml-4 mt-1 mb-2">
            <v-btn class="secondary--text mt-2 mr-3" text small @click="refresh()">
              <v-icon small class="mr-1">refresh</v-icon>
              Refresh
            </v-btn>
          </div>
        </v-layout>

        <v-layout class="mt-4">
          <p class="secondary--text caption">View latest text completions for this model.
          </p>
        </v-layout>

        <!-- Error message -->
        <v-layout>
          <div v-if="error">
            <v-alert type="error" dense outlined>
              {{ error }}
            </v-alert>
          </div>
        </v-layout>

        <!-- delete confirmation modal -->
        <v-dialog v-model="modal" max-width="500px">
          <v-card>
            <v-card-title>Confirmation Required</v-card-title>
            <v-card-text>
              <p>
                Are you sure want to delete image generate request '{{ deletingGen.id }}'?
              </p>
              <p>
                Data will be deleted permanently.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn text v-on:click.native="modal = false">Cancel</v-btn>
              <v-spacer> </v-spacer>
              <v-btn v-on:click.native="deleteGen(deletingGen)" text class="primary">
                Remove
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-layout class="mt-2">
          <div class="tbcard">
            <v-card>
              <v-data-table show-select v-if="model" v-model="selected" :key="tableKey" :headers="headers" :items="items"
                hide-default-footer :search="e3" :loading="loading" :server-items-length="pagination.totalItems"
                loading-text="Loading... Please wait">

                <template v-if="selected" v-slot:top>
                  <v-row>
                    <v-layout wrap class="justify-end mt-5 mr-5 ml-5">

                      <v-flex lg2 md5 sm12 height="25px">
                        <v-select class="mr-2" outlined dense v-model="status" :items="statuses" item-text="text"
                          item-value="value" label="Filter by status">
                        </v-select>
                      </v-flex>

                      <v-flex lg2 md2 sm12 class="mr-4">

                        <v-btn small height="40px" outlined class="secondary--text"
                          :disabled="selected && selected.length === 0" @click="deleteSelected()">
                          <v-icon small class="mr-1">delete</v-icon>
                          Delete Selected ({{ selected.length }})
                        </v-btn>
                      </v-flex>

                    </v-layout>
                  </v-row>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.createdAt | ago }}
                      </span>
                    </template>
                    <span>{{ item.createdAt | date }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.user`]="{ item }">
                  <router-link  class="no-underline" :to="{
                      name: 'userDetails',                                
                      params: {
                        userId: item.user,
                      },
                    }">
                    {{ item.user }}
                  </router-link>
                </template>

                {{/* */ }}

                <template v-slot:[`item.status`]="{ item }">
                  <v-tooltip max-width="400px" top :key="item.id">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">

                        <v-chip v-if="item.status === 'pending'" class="ma-2" label small color="secondary">
                          {{ item.status }}
                        </v-chip>

                        <v-chip v-if="item.status === 'processing'" class="ma-2" label small color="secondary">
                          {{ item.status }}
                        </v-chip>

                        <v-chip v-if="item.status === 'failed'" class="ma-2" label small color="error" text-color="white">
                          {{ item.status }}
                        </v-chip>

                        <v-chip v-if="item.status === 'completed'" class="ma-2" label small color="primary"
                          text-color="white">
                          {{ item.status }}
                        </v-chip>

                      </span>
                    </template>
                    <span>{{ item.reason }}</span>
                  </v-tooltip>
                </template>
                
                <template v-slot:[`item.input`]="{ item }">
                  <v-tooltip max-width="350px" top :key="`body-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        
                          <v-btn small icon class="secondary--text" :href="item.requestBodyPath" target="_blank" v-bind:key="item.id">
                            <v-icon small class="">mdi-open-in-new</v-icon>                            
                          </v-btn>
                        
                      </span>
                    </template>
                    <span>{{ item.requestBody }}</span>
                  </v-tooltip>

                  <v-btn small icon class="secondary--text">
                    <v-icon small @click="copyToClipboard(item.requestBody)">
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template>

                <template v-slot:[`item.output`]="{ item }">
                  <template v-for="(output, i) in item.outputs">
                    <v-btn :key="i" x-small text outlined class="secondary--text" :href="output.url" target="_blank">
                      <v-icon x-small class="mr-1">mdi-open-in-new</v-icon>
                      View
                    </v-btn>
                  </template>
                </template>

                <template v-slot:[`item.tokens`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.totalTokens }}
                      </span>
                    </template>
                    <span>Prompt: {{ item.promptTokens }} | completion: {{ item.completionTokens }}</span>
                  </v-tooltip>
                </template>
                
                <template v-slot:[`item.priority`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        <v-icon v-if="item.priorityClass === 'low'" class="red--text darken-2">mdi-menu-down</v-icon>
                        <v-icon v-if="item.priorityClass === 'medium'" class="primary--text">mdi-menu-up</v-icon>
                        <v-icon v-if="item.priorityClass === 'high'" class="green--text darken-2">mdi-menu-up</v-icon>
                      </span>
                    </template>
                    Priority: {{ item.priorityClass }} ({{ item.priority }})
                  </v-tooltip>
                </template>

                <template v-slot:[`item.cost`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.cost.toFixed(4) }}
                      </span>
                    </template>
                    <span>{{ item.cost }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu class="items" top left transition="v-slide-y-transition">
                    <template v-slot:activator="{ on: on }">
                      <v-btn icon v-on="on" :class="
                        $store.state.theme === 'light' ? 'secondary--text' : ''
                      ">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="deleteGen(item)">
                        <v-list-item-title>delete</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="item.outputs.length > 0"
                        @click="downloadFile(item.outputs[0].url, `${item.outputs[0].createdAt}-${item.outputs[0].id}`)">
                        <v-list-item-title>download</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <div class="pr-2 pb-2">
                <v-layout v-if="model" class="mt-2">
                  <v-spacer></v-spacer>
                  <span class="text-caption mt-3 mr-4">Rows per page:</span>
                  <div class="rows-select">
                    <v-flex shrink class="select">
                      <v-select class="mb-2" width="25" v-model="itemsPerPage" :items="itemsPerPageOptions" dense
                        label="Rows per page" single-line></v-select>
                    </v-flex>
                  </div>
                  <span class="text-caption mt-3 mr-4 ml-4">Total items: {{ pagination.totalItems }}</span>
                  <v-btn class="mr-2 mt-2" icon small router :disabled="(pagination.previousPageToken == '' && items.length > 0) ? true : false"
                    :to="{
                      name: 'modelLogs',
                      params: {
                        modelId: model.id,
                      },
                      query: {
                        pageToken: pagination.previousPageToken,
                      },
                    }">
                    <v-icon>navigate_before</v-icon>
                  </v-btn>
                  <!-- Navigate to the next page based on the token -->
                  <v-btn class="ml-2 mt-2" icon small router :disabled="pagination.nextPageToken == '' ? true : false"
                    :to="{
                      name: 'modelLogs',
                      params: {
                        modelId: model.id,
                      },
                      query: {
                        pageToken: pagination.nextPageToken,
                      },
                    }">
                    <v-icon>navigate_next</v-icon>
                  </v-btn>
                </v-layout>
              </div>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
// import axios from 'axios'
// import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';

export default {
  components: {
    // PageSubtitle,
    PageTitle,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      modal: false,
      deletingGen: {
        id: ''
      },
      selected: [],
      itemsPerPage: 10,
      itemsPerPageOptions: [
        10,
        20,
        200,
        500,
      ],      
      status: '',
      statuses: [
        { text: 'Pending', value: 'pending' },
        { text: 'Processing', value: 'processing' },
        { text: 'Completed', value: 'completed' },
        { text: 'Failed', value: 'failed' },
      ]
    }
  },

  watch: {
    // Table pagination
    itemsPerPage: {
      handler() {
        console.log('items per page changed')
        // If user is changing items per page, we kinda need
        // to reset the token, otherwise going backwards might
        // be weird
        let reset = this.itemsPerPage !== this.pagination.pageSize ? true : false
        this.$store.dispatch('SetImageGensPageSize', this.itemsPerPage).then(() => {
          if (reset && this.pageTokenFromUrl) {
            this.$router.push({ 
              name: 'modelLogs',
              params: {
                modelId: this.modelId,
              },
            }).catch(() => { })
          } else {
            this.loadItems()
          }
        })
      }
    },
    status: {
      handler() {
        this.loadItems()
      }
    },
    $route() {
      this.loadItems()
    }
  },

  computed: {
    modelId() {
      return this.$route.params.modelId
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    pageTokenFromUrl() {
      return this.$route.query.pageToken
    },
    model() {
      return this.$store.state.model.model
    },
    loading() {
      return this.$store.state.imageGens.loading
    },
    error() {
      return this.$store.state.imageGens.error
    },
    items() {
      return this.$store.state.imageGens.gens
    },
    headers() {
      if (this.model.modelType === 'text') {
        let textColumns = [
          { text: 'Timestamp', align: 'left', value: 'createdAt', sortable: true },
          { text: 'User', value: 'user', align: 'left' },
          { text: 'Status', value: 'status', align: 'left' },
          { text: 'Input', value: 'input', align: 'left' },
          { text: 'Output', value: 'output', align: 'left' },
          { text: 'Tokens', value: 'tokens', align: 'left' },          
        ]

        // If credits enabled, add credits column
        if (this.model.rules.credits) {
          textColumns.push({ text: 'Cost', value: 'cost', align: 'left' })
        }

        textColumns.push( { text: '', value: 'actions', sortable: false, align: 'right' })

        return textColumns
      }
      // Image gens
      let imageColumns = [
        { text: 'Timestamp', align: 'left', value: 'createdAt', sortable: true },
        { text: 'User', value: 'user', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Input', value: 'input', align: 'left' },
        { text: 'Output', value: 'output', align: 'left' },
      ]

      // If priority enabled, add priority column
      if (this.model.rules.priority) {
        imageColumns.push({ text: 'Priority', value: 'priority', align: 'left' })
      }

      // If credits enabled, add credits column
      if (this.model.rules.credits) {
        imageColumns.push({ text: 'Cost', value: 'cost', align: 'left' })
      }

      imageColumns.push( { text: '', value: 'actions', sortable: false, align: 'right' })

      return imageColumns
    },
    pagination() {
      return this.$store.state.imageGens.pagination
    },
    breadcrumbs() {
      if (!this.model) {
        return []
      }
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: this.model.name,
          disabled: false,
          href: `/models/${this.modelId}`,     
        },
        {
          text: 'Logs',
          disabled: true,
          href: '',
        },
      ]
    }
  },

  async mounted() {
    // Loading pagination preferences
    // await this.$store.dispatch('LoadImageGensPageSize')

    this.itemsPerPage = this.pagination.pageSize

    this.refresh()
  },

  methods: {
    async refresh() {
      await this.$store.dispatch('GetUser')

      // Getting the model
      const q = {
        projectId: this.project.id,
        modelId: this.modelId
      }
      await this.$store.dispatch('GetModel', q)

      await this.loadItems()

      this.$nextTick(() => {
        this.tableKey = Date.now()
      })
    },
    // Loads etiher text completions or image gens
    async loadItems() {
      console.log('loading items')
      await this.fetchGens()      
    },
    // Fetch generated images
    async fetchGens() {
      if (!this.project) {
        return
      }

      let q = {
        models: this.models, // models are for name lookup
        projectId: this.project.id,
        pageSize: this.pagination.pageSize,
        model: this.modelId,
        status: this.status,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
      }

      await this.$store.dispatch('ListImageGens', q)
    },
    deleteImageGenIntent(item) {
      console.log(item)      
    },
    async deleteGen(item) {
      await this.$store.dispatch('DeleteGen', { projectId: item.projectId, id: item.id })
      await this.refresh()
    },
    async deleteSelected() {
      // Dispatch DeleteImageGen for each item in the selected array and resolve their promises
      await Promise.all(this.selected.map(item => this.$store.dispatch('DeleteGen', { projectId: item.projectId, id: item.id })))
      await this.refresh()
    },
    downloadFile(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    },
    copyToClipboard(value) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(value)
    }
  }
}
</script>
